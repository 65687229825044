import React from 'react'
import productBg from '../components/images/products-1.jpg';
import Header from '../components/Header';
import {apiProducts} from '../components/api-products';

function APIProducts() {
  return (
    <div>
        <Header bkgImage={productBg} 
        title="Applied Pharmaceuticals Ingredients"
        subtitle="Our API products are listed down"
        />
        <div className='table-component'>
            <table>
                <thead>
                    <th>S No</th>
                    <th>Category & Products</th>
                    <th>
                        Pharmacopeial Grade
                    </th>
                    <th>
                        CAS No
                    </th>
                   
                </thead>
                {apiProducts.map((product, index) => {
    return (
        <React.Fragment key={index}>
            <tr>
                <td colSpan="4" className='category'>{product.category}</td>
            </tr>
            {product.products.length > 0 &&
                product.products.map((pr) => {
                    return (
                        <tr key={pr.sr_no}>
                            <td>{pr.sr_no}</td>
                            <td>{pr.name}</td>
                            <td>{pr.grade}</td>
                            <td>{pr.cas_no}</td>
                        </tr>
                    );
                })}
        </React.Fragment>
    );
})}

                
            </table>
        </div>
    </div>
  )
}

export default APIProducts;