import React, { useEffect } from "react";
import teamImage from "../components/images/teams.jpg";
import Header from "../components/Header";
import Vision from "../components/Vision";
import JourneyIntro from "../components/JourneyIntro";
import WhoWeAre from "../components/WhoWeAre";
import teamBkg from "../components/images/team-background.jpg";
import OurManagement from "../components/OurManagement";
import OurPresence from "../components/OurPresence";
import AboutSgmt from "../components/AboutSgmt";

import Vision2 from "../components/Vision2";

function About({ id }) {
  useEffect(() => {
    const targetElem = document.getElementById(id);
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [id]);
  return (
    <div className="about">
      <Header
        bkgImage={teamImage}
        title="About Us"
        subtitle="Know everything you need to know about us"
      />
      <div className="Aboutsgmr">
        <AboutSgmt />
      </div>
      <div className="full-page" id="1">
        {/* <Vision /> */}
        <Vision2/>
      </div>
      <div className="full-page" id="2">
        <WhoWeAre />
      </div>
      <div className="full-page" id="3">
        <OurManagement />
      </div>

      <div>
        
      </div>
      <div class="contact-form">
        <ul>
          <li className="form-contact">
            <form>
              <h3>Get in Touch With Us</h3>

              <input className="input" placeholder="Name" />
              <input className="input" placeholder="Email" type="email" />
              <input className="input" placeholder="Phone" type="phone" />
              <textarea
                className="input"
                placeholder="Message"
                rows="5"
                col="10"
              ></textarea>

              <button type="submit" className="form-btn">
                Submit
              </button>
            </form>
          </li>
        </ul>
      </div>
      {/* <div className='full-page' id="3">
      <OurPresence/>
    </div> */}
    </div>
  );
}

export default About;
