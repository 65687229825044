import React from 'react'
import { chemicals } from '../components/api-products'
import productBkg from '../components/images/products-1.jpg'
import Header from '../components/Header'

function SpecialChemicals() {
  return (
    <div>
        <Header
        bkgImage={productBkg}
        title="Speciality Chemicals"
        subtitle="Our API intermediates are listed down"
      />
        <div className='table-component'>
        <table>
          <thead>
            <tr>
              <th>S No</th>
              {/* <th>Catalogue No</th> */}
              <th>
                Chemical Name
              </th>
              <th>CAS No</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {chemicals.map((pr, index) => (
              <tr key={index}>
                <td>{index + 1}</td> {/*Assuming you want S No to be the index + 1 */}
                {/* <td>{pr.sno}</td> */}
                <td>{pr.Chem}</td>
                <td>{pr.casno}</td>
                <td>{pr.Price}</td> {/*If pr.api is an object/array, this will show its structure */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SpecialChemicals;