import React from 'react'
import Header from '../components/Header'
import contact from '../components/images/contact-us.jpg'


function Contact() {
    const address = [
        {
            "officeType": "Manufacturing Center",
            "offices": [
                {
                    "country": "India",
                    "address": "Plot No:10, Gaddapotharam, Jinnaram Mandal, Sangareddy district, Telangana-India, Pin code-502319",
                    
                    "phone": "+91-9032001889"
                }
            ]
        },
        {
            "officeType": "Marketing Office",
            "offices": [
                {
                    "country": "UAE",
                    "address": "GMR International LLC, Capital Golden Tower - Office 906, Business Bay – Dubai, United Arab Emirates",
                    "email": "sales@gmrpharma.com",
                    "phone": "+971585396686"
                },
                {
                    "country": "UK",
                    "address": "Appt. 615 Jefferson Place 1 Fernie Street, Manchester, United Kingdom, M4 4BL",
                    "email": "sales@gmrpharma.com",
                    "phone": ""
                }
            ]
        },
        {
            "officeType": "Corporate Offices",
            "offices": [
                {
                    "country": "India",
                    "address": "#501c, 5th Floor, Bankers Chambers, AS Raju Nagar, Kukatpally, Hyderabad - 500072, Telangana, India",
                    "email": "sales@sgmrpharma.com",
                    "phone": "040-23061889"
                }
            ]
        },
        {
            "officeType": "R&D Center",
            "offices": [
                {
                    "country": "India",
                    "address": "Phase 5, Plot no 51, Navodaya industrial estate, IDA Cherlapally, Hyderabad, Telangana-500051",
                    "email": "purchase@sgmrpharma.com",
                    "phone": "040-23061889"
                }
            ]
        }
    ];
    
  return (
    <div>
        <Header
         bkgImage={contact}
         title="Contact Us"
         subtitle="Join SGMR and enhance your career possiblities"/>
        <div className='address'>
            <h3>Our Office Address</h3>
            <ul>
                {address.map((addre)=>{
                    return (
                        <li>
                    <div className='address-card'>
                         <h4 style={{display:'flex',alignItems:'center',}}><span className='address-icon'>
                            <img style={{height:'25px',width:'25px'}} src="https://cdn-icons-png.flaticon.com/128/854/854901.png"/>
                            </span>{addre.officeType}</h4>
                         <ul>
                            {addre.offices.map((off)=>{
                                return <li>
                                          <h5 className='country'>{off.country}</h5>
                                          <p className='address'>{off.address}</p>
                                          <p className='email'>{off.email}</p>
                                          <p className='phone'>{off.phone}</p>
                                       </li>
                            })}
                             
                            
                         </ul>
                        </div>
                        </li>
                        
                    );
                })}
                
                
            </ul>
            </div>
            <div class='contact-form'>
               <ul>
                <li className='form-contact'>
                    <form>
                        <h3>Get in Touch With Us</h3>
                        
                        
                        <input className='input' placeholder='Name' />
                        <input className='input' placeholder='Email' type='email' />
                        <input className='input' placeholder='Phone' type="phone" />
                         <textarea className='input' placeholder='Message' rows="5" col="10">
                
                        </textarea>

                        <button type="submit" className='form-btn'>
                            Submit
                        </button>
                    


                    </form>

                </li>
                <li>
                    <div className='map'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.303318525387!2d78.40199907503715!3d17.49302838341234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91954b00cb9d%3A0xd483a077c38fcba3!2sSGMR%20Pharmaceuticals%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1727245236810!5m2!1sen!2sin"    style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </li>
               </ul>
            </div>
         
    </div>
  )
}

export default Contact